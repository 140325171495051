<template>
  <div class="main_container">
    <div class="row mb-3 bread-crumbs">
      <div class="col-12">
        <router-link :to="'/programme'">
          Programme>
        </router-link>
        <span>Détails du programme</span>
      </div>
    </div>
    <div class="row justify-content-center mb-4">
      <div class="col-8">
        <div class="card title-card">
          <div class="card-body text-center title">
            Détails du programme
          </div>
        </div>
      </div>
    </div>
    <div v-if="this.$route.params.id">
      <div class="inner_container">
        <div class="row">
          <Notif :notif="notif" />
        </div>
        <!-- <p class="successmsg">{{ updateProgrammemsg }}</p>
        <p class="errormsg"
           v-if="progerrors && progerrors != null">
          {{ progerrors }}
        </p> -->
        <div class="row action_bar">
          <div class="col-md-8 mx-auto text-center">
            <button type="button"
                    class="btn btn-f-blue "
                    @click="getBack()"><strong>&#x21E6;</strong> Retour</button>
            <button v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                    type="button"
                    class="btn btn-f-blue mx-4"
                    @click="launchUpdate($route.params.id)">
              <i class="flaticon flaticon-pencil"></i>
              &nbsp; &nbsp;Modifier
            </button>
            <button v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                    type="button"
                    class="btn btn-f-blue mr-4"
                    data-toggle="modal"
                    data-target="#exampleModal">
              <i class="flaticon flaticon-delete"></i>
              &nbsp; &nbsp;Supprimer
            </button>
            <button v-if="(user[1] == 'FER' && user[0] == 'DT')||(user[1] == 'FER' && user[0] == 'ADMIN')"
                    type="button"
                    class="btn btn-f-blue"><i class="flaticon flaticon-folder"></i>&nbsp; &nbsp;Clôturer</button>
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label>Catégorie du programme</label>
            <input type="text"
                   v-model="form.categorie_programme"
                   class="form-control"
                   disabled />
          </div>
          <div class="col-md-3">
            <label>Nom du programme</label>
            <input type="Text"
                   id=""
                   class="form-control"
                   v-model="form.nom_programme"
                   disabled />
          </div>
          <div class="col-md-3">
            <label>Année d'exercice</label>
            <input
              type="text"
              name="annee_exercice"
              v-model="form.annee_exercice"
              :ref="'annee_exercice'"
              class="form-control"
              v-on:click="removeSPan('annee_exercice')"
              v-on:change="getDate()"
              aria-label=" example"
              disabled
            />
            <span v-if="form.errors().has('categorie_programme') && affiche.categorie_programme !==   'categorie_cloture_programme'"
                  v-text="form.errors().get('categorie_cloture_programme')"
                  class="errorMsg"> </span>
          </div>
          <div class="col-md-3">
            <label>Date de validation</label>
            <input type="date"
                   class="form-control"
                   v-model="form.date_validation_programme"
                   disabled />
          </div>
        </div>
        <div class="row">
          <div class="col-md-3">
            <label>Date début</label>
            <input type="date"
                   class="form-control"
                   v-model="form.date_debut"
                   disabled />
          </div>
          <div class="col-md-3">
            <label>Date fin</label>
            <input type="date"
                   v-model="form.date_fin"
                   class="form-control"
                   disabled />
          </div>
          <div class="col-md-3">
            <label>Date de lancement</label>
            <input type="date"
                   id=""
                   class="form-control"
                   v-model="form.date_lancement_programme"
                   disabled />
          </div>
          <div class="col-md-3">
            <label>Date clôture</label>
            <input type="date"
                   class="form-control"
                   v-model="form.date_cloture_programme"
                   disabled />
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-3">
          <label>Date de rapport de clôture</label>
          <input type="date"
                 v-model="form.date_transmission_rapport_cloture_programme"
                 class="form-control"
                 disabled />
        </div>  
        <div class="col-md-3">
          <label>Montant initial</label>
          <input type="text"
                 class="form-control"
                 v-model="form.montant_initial_programme"
                 disabled />
        </div>
        <div class="col-md-3">
          <label>Montant révisé</label>
          <input
            type="text"
            name="budget"
            min="0"
            max="999999999999"
            v-model="form.montant_revise"
            class="form-control"
            v-on:click="removeSPan('montant_revise')"
            aria-label=" example"
            disabled
          />
          <span v-if="form.errors().has('montant_revise') && affiche.montant_revise !== 'montant_revise'"
                v-text="form.errors().get('montant_revise')"
                class="errorMsg"> </span>
        </div>
        <div class="col-md-3">
          <label>Montant définitif</label>
          <input
            type="text"
            name="budget"
            min="0"
            max="999999999999"
            v-model="form.montant_definitif"
            class="form-control"
            v-on:click="removeSPan('montant_definitif')"
            aria-label=" example"
            disabled
          />
          <span v-if="form.errors().has('montant_definitif') && affiche.montant_definitif !== 'montant_definitif'"
                v-text="form.errors().get('montant_definitif')"
                class="errorMsg"> </span>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <label>Description</label>
          <textarea class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    v-model="form.description"
                    disabled> </textarea>
        </div>
      </div>
      <div class="row">
        <div class="col-md-5 "></div>
        <div class="col_header total col-md-3 mr-5 mt-5 text-right">
          TOTAL MONTANT REVISE ACTIVITE: <span>{{total_revise_activite.toLocaleString()}} FCFA</span>
        </div>
        <div class="col_header total col-md-3 mt-5 ml-2 text-right">
          TOTAL MONTANT DEFINITIF ACTIVITE: <span>{{total_definitif_activite.toLocaleString()}} FCFA</span>
        </div>
      </div>
      <div class="table_holder">
        <div class="tab_holder">
          <div class="col_table"
               v-if="assignProgramme.programme">
            <div class="col_header">
              <div class="row">
                <div class="col-1">DESIGNATION</div>
                <div class="col-1 text-center">OBSERVATION</div>
                <div class="col-2 text-center">NOMBRE DE MARCHES ALLOUES</div>
                <div class="col-2 text-right">MONTANT ALLOUE (FCFA)</div>
                <div class="col-2 text-right">MONTANT INITIAL (FCFA)</div>
                <div class="col-2 text-right">MONTANT REVISE (FCFA)</div>
                <div class="col-2 text-right">MONTANT DEFINITIF (FCFA)</div>
              </div>
            </div>
            <div class="row no-mr sub-header"
                 v-for="(rubrique, rubkey) in assignProgramme.programme.rubriques"
                 :key="rubkey">
              <div class="col-md-12">
                <div class="row light_row">
                  <div class="col-md-4">
                    <label>{{ rubrique.libelle }}</label>
                  </div>
                  <!-- <div class="col-md-3"></div> -->
                  <div class="col-md-2 text-right">
                    <label
                    ><span class="badge badge-pill badge-secondary">{{ Math.round(Number(rubrique.montant_alloue)).toLocaleString() }}</span></label
                    >
                  </div>
                  <div class="col-md-2 text-right">
                    <label
                    ><span class="badge badge-pill badge-warning">{{ Math.round(Number(rubrique.montant_budgete)).toLocaleString() }}</span></label
                    >
                  </div>
                  <div class="col-md-2 text-right">
                    <label
                    ><span class="badge badge-pill badge-info">{{ Math.round(Number(rubrique.montant_revise)).toLocaleString() }}</span></label
                    >
                  </div>
                  <div class="col-md-2 text-right">
                    <label
                    ><span class="badge badge-pill badge-info">{{ Math.round(Number(rubrique.montant_definitif)).toLocaleString() }}</span></label
                    >
                  </div>
                  
                </div>
                <div class="row alter tableview"
                     v-for="(activite, actkey) in rubrique.activites"
                     :key="actkey">
                  <div class="col-md-1 ">
                    {{ activite.libelle }}
                  </div>
                  <div class="col-md-1 tb_row text-center">
                    {{ activite.observation }}
                  </div>
                  <div class="col-md-2 tb_row text-center montant">
                    {{ Math.round(Number(activite.total_marches)).toLocaleString() }}
                  </div>
                  <div class="col-md-2 tb_row text-right montant">
                    {{ Math.round(Number(activite.montant_alloue)).toLocaleString() }}
                  </div>
                  <div class="col-md-2 tb_row text-right montant">
                    {{ Math.round(Number(activite.montant_budgete)).toLocaleString() }}
                  </div>
                  <div class="col-md-2 tb_row text-right montant">
                    {{ Number(activite.montant_revise).toLocaleString() }}
                  </div>
                  <!-- <div class="col-md-2 tb_row text-right montant"
                       v-else>
                    0
                  </div> -->
                  <div class="col-md-2 tb_row text-right montant">
                    {{ Math.round(Number(activite.montant_definitif)).toLocaleString() }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-else
         class="row  mb-4">
      {{ noData }}
    </div>
    <!-- Modal -->
    <div class="modal fade"
         id="exampleModal"
         tabindex="-1"
         role="dialog"
         aria-labelledby="exampleModalLabel"
         aria-hidden="true">
      <div class="modal-dialog"
           role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title"
                id="exampleModalLabel">Supprimer un programme</h5>
            <button type="button"
                    class="close"
                    ref="btn-close"
                    data-dismiss="modal"
                    aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body text-center">
            Voulez-vous vraiment supprimer ce programme?
          </div>
          <div class="modal-footer">
            <button type="button"
                    class="btn btn-secondary"
                    @click="supprimerProgramme()">Oui</button>
            <button type="button"
                    class="btn btn-primary"
                    data-dismiss="modal">Non</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style>
@import "./programme.css";
.col_table .col_header,
.col_header.total,
.col_table .sub-header label,
.col_table .sub-header .tableview
{
  font-size: 0.48em;
}
.col_table .sub-header div.row.tableview > div.tb_row
{
  font-size: 1.3em;
}
span.badge.badge-pill,.col_header span{
  font-size: 1.3em;
}
</style>

<script>
import form from "vuejs-form"
import { mapActions, mapMutations, mapGetters } from "vuex"
import Notif from "@/components/shared/Toast"

export default {
  name: "DetailProgramme",
  components: {
    Notif
  },
  data: () => ({
    noData: "",
    total_definitif_activite:0,
    total_revise_activite:0,
    form: form({
      nom_programme: "",
      montant_initial_programme: "",
      montant_revise: "",
      montant_definitif: "",
      date_debut: "",
      date_fin: "",
      description: "",
      date_validation_programme: "",
      date_lancement_programme: "",
      date_transmission_rapport_cloture_programme: "",
      date_cloture_programme: "",
      annee_exercice: "",
      categorie_programme: "",
      activites: []
    }).rules({
      nom_programme: "required",
      montant_initial_programme: "required",
      date_debut: "required",
      date_fin: "required",
      description: "required"
    }),
    notif: {
      activated: "",
      type: "",
      message: ""
    },
    user: ""
  }),
  watch: {
    assignProgramme() {
      var counter = 0
      var listActivities = []
      if (this.assignProgramme.programme) {
        this.form.nom_programme = this.assignProgramme.programme.nom_programme
        this.form.montant_initial_programme = Math.round(this.assignProgramme.programme.montant_initial_programme).toLocaleString() + " FCFA"
        this.form.montant_revise = Math.round(this.assignProgramme.programme.montant_revise).toLocaleString() + " FCFA"
        this.form.montant_definitif = Math.round(this.assignProgramme.programme.montant_definitif).toLocaleString() + " FCFA"
        this.form.date_debut = this.assignProgramme.programme.date_debut
        this.form.date_fin = this.assignProgramme.programme.date_fin
        this.form.date_fin = this.assignProgramme.programme.date_fin
        this.form.date_validation_programme = this.assignProgramme.programme.date_validation_programme
        this.form.date_lancement_programme = this.assignProgramme.programme.date_lancement_programme
        this.form.date_transmission_rapport_cloture_programme = this.assignProgramme.programme.date_transmission_rapport_cloture_programme
        this.form.date_cloture_programme = this.assignProgramme.programme.date_cloture_programme
        this.form.annee_exercice = this.assignProgramme.programme.annee_exercice
        this.form.description = this.assignProgramme.programme.description
        this.form.categorie_programme = this.assignProgramme.programme.categorie_programme
        const rubArray = this.assignProgramme.programme.rubriques
        var total=0
        var total_revise=0
        rubArray.filter(function(actArr, rubindex) {
          actArr.activites.filter(function(activity, actindex) {
            const insertActivity = { id: "", observation: "", montant: "" }
            insertActivity.id = activity.id
            insertActivity.observation = activity.observation
            insertActivity.montant = activity.montant_budgete
            total += Number(activity.montant_definitif)
            if (isNaN(activity.montant_revise) || activity.montant_revise==null || activity.montant_revise=="") {
              total_revise += 0
            }else{
              total_revise += Number(activity.montant_revise)
            }
            console.log(activity.montant_revise)
            listActivities.push(insertActivity)
            rubArray[rubindex].activites[actindex]["counter"] = counter
            counter += 1
          })
        })
        this.total_definitif_activite = total
        this.total_revise_activite = total_revise
        this.form.activites = listActivities
      }
    },
    deleteProgrammemsg() {
      if (this.deleteProgrammemsg) {
        this.notif.message = this.deleteProgrammemsg
        this.notif.type = "toast-success"
        this.notif.activated = "activated"
        setTimeout(
          function() {
            this.notif.activated = ""
            this.deleteProgramme("")
            // location.reload()
            this.$refs["btn-close"].click()
            this.$router.push({ name: "Programme" })
          }.bind(this),
          3000
        )
      }
    },
    progerrors() {
      this.notif.message = this.progerrors
      this.notif.type = "toast-danger"
      this.notif.activated = "activated"
      setTimeout(
        function() {
          this.notif.activated = ""
          this.$refs["btn-close"].click()
          this.setErors("")
        }.bind(this),
        3000
      )
    }
  },
  created() {
    const userdetails = JSON.parse(localStorage.getItem("user"))
    this.user = userdetails.user_role.split("_")
    if (this.$route.params.id) {
      this.getProgramme(this.$route.params.id)
    } else {
      this.noData = "No record"
    }
  },
  computed: {
    ...mapGetters(["assignProgramme", "updateProgrammemsg", "deleteProgrammemsg", "progerrors"])
  },
  methods: {
    ...mapActions(["getProgramme", "updateProgrammes", "deleteProgrammes"]),
    ...mapMutations(["setErors", "deleteProgramme"]),
    launchUpdate(id) {
      this.$router.push({ name: "ModifyProgramme", params: { id: id } })
    },
    getBack() {
      this.$router.push({ name: "Programme" })
    },
    supprimerProgramme() {
      this.deleteProgrammes(this.$route.params.id)
    }
  }
}
</script>
